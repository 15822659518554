
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { notificationsOutline, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonList, IonItem, IonLabel, IonIcon, IonBackButton,
        IonThumbnail, IonButtons, IonButton, IonBadge, IonChip,
        IonInfiniteScroll, IonInfiniteScrollContent, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import NotificationItem from "@/components/NotificationItem.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  name: 'NotificationListPage',
  components: { IonContent, IonPage, IonGrid, IonList, IonItem, IonLabel, IonIcon, IonBackButton,
                IonThumbnail, IonButtons, IonButton, IonBadge, IonChip,
                IonInfiniteScroll, IonInfiniteScrollContent,
                NotificationItem, LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { getRelativeDate, infiniteScrollLoadData } = utils();

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingNotifications);
    const allNotifications = computed(() => store.state.allNotifications);
    const numOfVisibleItems = ref(20);

    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, allNotifications.value);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, getRelativeDate, loadData,

      // icons
      notificationsOutline,

      // variables
      loading, allNotifications, numOfVisibleItems,
      currUser,
    }
  }
}
