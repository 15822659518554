import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d625728"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "full",
    "router-link": {
      name: 'NotificationDetailPage',
      params: { id: $props.notification.id }
    },
    button: "",
    detail: ""
  }, {
    default: _withCtx(() => [
      ($props.notification.photo)
        ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
            key: 0,
            slot: "start"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: $props.notification.photoLink
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_icon, {
            key: 1,
            icon: $setup.notificationsOutline,
            slot: "start"
          }, null, 8, ["icon"])),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString($props.notification.title), 1),
          _createElementVNode("p", null, _toDisplayString($props.notification.content), 1),
          _createElementVNode("p", null, [
            _createElementVNode("small", null, _toDisplayString($setup.getRelativeDate($props.notification.scheduledToSendAt)), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}