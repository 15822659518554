import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_notification_item = _resolveComponent("notification-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('notifications'),
        parentPath: "/home"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_loading_skeleton, { key: 0 }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allNotifications.slice(0, $setup.numOfVisibleItems), (notification) => {
                        return (_openBlock(), _createBlock(_component_notification_item, {
                          key: notification.id,
                          notification: notification
                        }, null, 8, ["notification"]))
                      }), 128))
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_infinite_scroll, {
                onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => ($setup.loadData($event))),
                threshold: "100px",
                id: "infinite-scroll"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_infinite_scroll_content, {
                    "loading-spinner": "bubbles",
                    "loading-text": $setup.t('loadingMore')
                  }, null, 8, ["loading-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}