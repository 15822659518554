
// icons
import { notificationsOutline, close, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonBadge, IonIcon, IonThumbnail, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "notification",
  ],
  components: { IonItem, IonLabel, IonBadge, IonIcon, IonThumbnail, },
  setup() {
    const { t } = useI18n();
    const { getRelativeDate } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notificationsOutline, close,

      // methods
      t, getRelativeDate,
    }
  }
}
